import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../components/layout"
import { graphql } from 'gatsby'

import styles from "./booking.module.scss";

export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Booking | {data.site.siteMetadata.title}</title>
      </Helmet>

      {data.allWordpressPage.edges.map(({ node }) => (
        <div className={styles.booking}>
          <h1>{node.title}</h1>

          <iframe src="https://admin.fumerestobar.ca/booking/" frameborder="0" title="Booking" width="100%" height="600px"></iframe>
        </div>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,
        desc,
        intro
      }
    },
    allWordpressPage(
      filter: { slug:{ eq: "booking" }}
    ) {
      edges {
        node {
          title
          content
          slug
        }
      }
    }
  }
`
